import "./App.css";
import { useEffect, useState } from "react";
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentIpAddress, setCurrentIpAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setCurrentIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  const fieldsStyle = {
    padding: "10px",
    width: "250px",
    borderRadius: "100px",
    outline: "none",
    boxShadow: "none",
    border: "none",
  };
  const onFormSubmit = (e) => {
    try {
      e.preventDefault();
      const userName = e.target.username.value.trim();
      const password = e.target.password.value.trim();
      if (
        userName === process.env.REACT_APP_USERNAME &&
        password === process.env.REACT_APP_PASSWORD
      ) {
        setIsLoggedIn(true);
      } else {
        alert("Invalid Credentials");
      }
    } catch (error) {
      console.error(error);
      alert("Something terribly went wrong :(");
    }
  };
  const addIP = (e) => {
    e.preventDefault();
    const ipValue = (e.target.ip.value + "").trim();
    const ipName = (e.target.ip_name.value + "").trim();
    if (!ipName) {
      alert("Enter a valid IP name");
      return;
    }
    if (!/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(ipValue)) {
      alert("Invalid IPv4");
      return;
    }

    setIsLoading(true);
    fetch(process.env.REACT_APP_AWS_URL, {
      method: "POST",
      mode: "no-cors",
      body: JSON.stringify({
        my_ip: ipValue,
        name: e.target.ip_name.value,
      }),
    })
      .then(() => {
        setIsLoading(false);
        alert("SUCCESS: IP added!");
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
        alert("ERROR: Failed to Add IP!");
      });
  };
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={
            "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage%20/%201685586307248_profile"
          }
          alt="logo"
        />
        {isLoggedIn ? (
          <>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
              onSubmit={addIP}
            >
              <h3>ADD IPV4</h3>
              <h5>Current IP : {currentIpAddress}</h5>
              <input
                name="ip_name"
                placeholder="Ip name"
                required
                minLength={4}
                style={fieldsStyle}
                type="text"
              />
              <input
                name="ip"
                placeholder="Your Ip address"
                required
                defaultValue={currentIpAddress}
                style={fieldsStyle}
                type="text"
              />

              <input
                style={{
                  ...fieldsStyle,
                  background:
                    "linear-gradient(90deg, #5351FC 0%, #19A9FC 100%)",
                  width: "50%",
                  cursor: "pointer",
                  color: "white",
                  fontWeight: "bold",
                  opacity: isLoading ? 0.5 : 1,
                }}
                disabled={isLoading}
                type="submit"
                value={isLoading ? "Adding..." : "Submit"}
              />
            </form>
          </>
        ) : (
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
            }}
            onSubmit={onFormSubmit}
          >
            <h1>Login</h1>
            <input
              required
              name="username"
              placeholder="Username"
              style={fieldsStyle}
              type="text"
            />
            <input
              required
              name="password"
              placeholder="Password"
              style={fieldsStyle}
              type="password"
            />
            <input
              style={{
                ...fieldsStyle,
                background: "linear-gradient(90deg, #5351FC 0%, #19A9FC 100%)",
                width: "50%",
                cursor: "pointer",
                color: "white",
                fontWeight: "bold",
              }}
              type="submit"
            />
          </form>
        )}
      </header>
    </div>
  );
}

export default App;
